<template>
  <v-dialog v-model="showDialog" transition="scale-transition" max-width="400">
    <v-card class="rounded-xl elevation-12">
      <v-card-title class="pa-3 title-modal text-center justify-center" color="#8e84c0">
        DETALLES DEL MÉTODO DE PAGO
      </v-card-title>
      <v-card-text class="pa-6 mb-1">
        <v-list class="pa-0">


          <template v-if="withdrawalMethod === 'bank'">
            <v-list-item-group v-for="( key, index) in orderedKeys" :key="key">
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <v-icon color="#8e84c0" size="26">{{ getIcon(key) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium text-left text-titulos">
                    {{ getLabel(key) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">
                    {{ paymentDetails[key] }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < orderedKeys.length - 1" class="color-divider" />
            </v-list-item-group>
          </template>


          <template v-else-if="withdrawalMethod === 'crypto'">
            <v-list-item class="px-0">
              <v-list-item-icon>
                <v-icon color="#8e84c0">mdi-wallet</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class=" font-weight-medium text-left text-titulos">
                  DIRECCIÓN DE WALLET (RED TRC20)
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">
                  {{ paymentDetails.wallet_address }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else-if="withdrawalMethod === 'cosmo'">
            <v-list-item class="px-0">
              <v-list-item-icon>
                <v-icon color="#8e84c0">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium text-left text-titulos">
                  ID de Cosmo
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">
                  {{ paymentDetails.cosmo_id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else-if="withdrawalMethod === 'paypal'">
            <v-list-item class="px-0">
              <v-list-item-icon>
                <v-icon color="#8e84c0">mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium text-left text-titulos">
                  CORREO ELECTRÓNICO DE PAYPAL
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">

                  {{ paymentDetails.email }}

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

        </v-list>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="0" color="#8e84c0" class="px-6" icon small v-bind="attrs" v-on="on"
              @click="clipboard(textCopy)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copiar</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-btn color="#8e84c0" text @click="showDialog = false" class="px-6 button-close">
          CERRAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      orderedKeys: [
        'country',
        'type_document',
        'document_number',
        'name',
        'last_name',
        'type_account',
        'bank',
        'account_number',
      ],
      textCopy: ''
    };
  },
  props: {
    dialogDetails: {
      type: Boolean,
      required: true,
    },
    withdrawalMethod: {
      type: String,
      default: null,
      validator: (value) => value === null || ["bank", "crypto", "cosmo", "paypal"].includes(value),
    },
    paymentDetails: {
      type: Object,
      required: true,
    },
  },

  computed: {
    showDialog: {
      get() {
        return this.dialogDetails;
      },
      set(value) {
        this.$emit("update:dialogDetails", value);
      },
    },
  },

  watch: {
    dialogDetails: {
      immediate: true,
      handler() {
        this.generarTextoACopiar();
      },
    },
  },

  methods: {
    getIcon(key) {
      const icons = {
        country: "mdi-flag",
        type_document: "mdi-card-account-details",
        document_number: "mdi-identifier",
        type_account: "mdi-piggy-bank",
        bank: "mdi-bank",
        account_number: "mdi-numeric",
        name: "mdi-account",
        last_name: "mdi-account",
      };
      return icons[key] || "mdi-information";
    },

    clipboard(str) {
      navigator.clipboard.writeText(str).then(() => {
        this.$vs.notify({
          title: 'Copiado al portapapeles',
          text: 'Detalles del método de pago copiados al portapapeles',
          color: 'rgb(95 189 233)',
          icon: 'content_copy',
          position: 'bottom-center',
          time: 5000,
        })
      }, () => {

      })
    },

    getLabel(key) {
      const labels = {
        country: "País",
        type_document: "Tipo de documento",
        document_number: "Número de documento",
        name: "Nombre del titular",
        last_name: "Apellido del titular",
        type_account: "Tipo de cuenta",
        bank: "Banco",
        account_number: "Número de cuenta",
      };
      return (labels[key] || key).toUpperCase();
    },

    generarTextoACopiar() {
      if (this.withdrawalMethod === 'crypto') {
        this.orderedKeys = ['wallet_address'];
      } else if (this.withdrawalMethod === 'cosmo') {
        this.orderedKeys = ['cosmo_id'];
      } else if (this.withdrawalMethod === 'paypal') {
        this.orderedKeys = ['email'];
      } else {
        this.orderedKeys = [
          'country',
          'type_document',
          'document_number',
          'name',
          'last_name',
          'type_account',
          'bank',
          'account_number',
        ];
      }
      this.textCopy = this.orderedKeys
        .map(key => `${this.getLabel(key)}: ${this.paymentDetails[key]}`)
        .join('\n');
    },
  },
};
</script>

<style>
.title {
  font-size: 22px !important;
  color: #8061c2;
}

.desc {
  color: #737373;
  font-size: 15px;
}

.title-modal {
  background-color: #8e84c0;
  color: white;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.text-titulos {
  color: #8e84c0;
  font-size: 13px !important;
  font-weight: 700 !important;
}

.button-close {
  font-weight: 700 !important;
}
</style>
